import React from 'react'
import './Loader.css'
export default function Loader() {

  return (
    <div className="loaders">
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    </div>
  )
}
